<template>
  <div class="FormWrap" v-loading="loading">
    <!-- <mia-navback :isShowSave="false"/> -->
    <div class="content">
      <mia-Card title="基础信息">
        <div style="margin-left: 4px" slot="content">
          <!-- <SimpleForm
                        lableWidth="180px"
                        :form="form"
                        :formFields="formFields"
                        :isShowSave="false"
                        :isShowCancel="false"
                    /> -->
          <el-form label-width="180px">
            <el-form-item label="姓名">
              <el-row>
                <el-col :span="6">{{ form.name }}</el-col>
                <el-col :span="6" :push="1">
                  <!-- <el-button  type="primary" @click="changeName">修改名称</el-button> -->
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="登录账号">
              <el-row>
                <el-col :span="6">{{ form.loginName }}</el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="角色">
              <el-row>
                <el-col :span="6">{{ form.role }}</el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </div>
      </mia-Card>
      <mia-Card title="登录信息">
        <div style="margin-left: 4px" slot="content">
          <el-form label-width="180px">
            <el-form-item label="最新登录时间">
              <el-row>
                <el-col :span="6">{{ lastLoginTime }}</el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="密码">
              <el-row>
                <el-col :span="6"
                  >安全性高的密码可以使帐号更安全，建议您的密码长度不少于8位，且包括数字和字母</el-col
                >
                <el-col :span="6" :push="1">
                  <el-button type="primary" @click="changePassword"
                    >修改密码</el-button
                  >
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="手机号">
              <el-row>
                <el-col :span="6">
                  <el-input v-model="form.contact" :disabled="true"></el-input>
                </el-col>
                <el-col :span="6" :push="1">
                  <el-button type="primary" @click="changePhone">{{
                    form.contact ? "修改手机" : "设置手机"
                  }}</el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </div>
      </mia-Card>
    </div>
    <miaDialog ref="changeName" v-if="changeNameVisible">
      <div slot="content">
        <el-form
          ref="changeNameForm"
          :rules="changeNameRules"
          :model="changeNameForm"
          label-width="80px"
        >
          <el-form-item label="姓名" key="name" prop="phone">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-form>
        <div class="footer">
          <el-button type="primary" @click="submitChangeName()"
            >提 交</el-button
          >
          <el-button @click="close">取 消</el-button>
        </div>
      </div>
    </miaDialog>
    <!-- 修改密码弹窗 -->
    <miaDialog ref="changePassword" v-if="changePasswordVisible">
      <div slot="content">
        <changePassword @close="close" />
      </div>
    </miaDialog>
    <miaDialog ref="changePhone" v-if="changePhoneVisible">
      <div slot="content">
        <changePhone :account="form.contact" @close="close" />
      </div>
    </miaDialog>
  </div>
</template>
<script>
import { GetInformation } from "@/api/system.js";
import changePassword from "./changePwd.vue";
import changePhone from "./changePhone.vue";
export default {
  components: { changePassword, changePhone },
  data() {
    return {
      loading: false,
      userId: null,
      form: {
        name: "",
        loginName: "",
        role: "",
        contact: "",
      },
      lastLoginTime: "",
      formFields: [
        { name: "姓名", field: "name", colSpan: 6, disabled: true },
        { name: "用户名", field: "loginName", colSpan: 6, disabled: true },
        { name: "角色", field: "role", colSpan: 6, disabled: true },
      ],
      changeNameForm: {
        name: "",
      },
      changeNameRules: {
        name: [
          { required: true, message: "请填写名称", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "名称长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
      },
      changePasswordVisible: false,
      changePhoneVisible: false,
      changeNameVisible: false,
    };
  },
  created() {
    this.userId = sessionStorage.getItem("userId");
    this.getInformation();
  },
  methods: {
    // 获取个人信息
    getInformation() {
      this.loading = true;
      GetInformation(this.userId).then((res) => {
        if (res.code === 1 && res.data) {
          // -1 超级管理员 0 是默认用户 1 是自定义
          const { contact, loginName, name, type, lastLoginTime } = res.data;
          this.form.name = name;
          this.form.loginName = loginName;
          if (type == 1) {
            this.form.role = "自定义用户";
          } else if (type == 0) {
            this.form.role = "默认用户";
          } else if (type == 0) {
            this.form.role = "超级管理员";
          }
          this.form.contact = contact;
          this.lastLoginTime = lastLoginTime;
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      });
    },
    changeName() {
      this.changeNameVisible = true;
    },
    // 修改密码
    changePassword() {
      this.changePasswordVisible = true;
      this.$nextTick(() => {
        this.$refs.changePassword.init("修改密码");
      });
    },
    // 修改手机号
    changePhone() {
      this.changePhoneVisible = true;
      this.$nextTick(() => {
        this.$refs.changePhone.init(
          this.form.contact ? "修改手机" : "设置手机"
        );
      });
    },
    close() {
      this.$nextTick(() => {
        this.changePhoneVisible = false;
        this.changePasswordVisible = false;
        this.changeNameVisible = false;
        this.getInformation();
      });
    },
    submitChangeName() {
      this.$refs["changeNameForm"].validate((valid) => {
        if (valid) {
          console.log();
          //    resetPhone(this.form).then(res => {
          //        if(res.code === 1) {
          //            this.$message.success('操作成功')
          //            this.close()
          //        } else {
          //            this.$message.error(res.msg)
          //        }
          //    })
        } else {
          return false;
        }
      });
    },
  },
};
</script>
